import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/common/user/user.service';
import { routes } from '../../consts/routes';
import { ConstantService } from '../constant/constant.service';
import { Plan, WebsiteAnalyze } from '../models/user.models';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { DataService } from 'src/app/services/common/sidabar/Data.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  showFiller = false;
  public routes: typeof routes = routes;
  public isOpenUiElements = false;
  public routeHome: string;
  public routeHomePathFirstParty: string;
  show: boolean;
  isDemo: boolean = false;
  isFPD: boolean = false;
  showFirstPartyDetailSidebar: boolean;
  public isAdmin: boolean = false;
  isPageOnly: boolean = false;
  url: any;
  expandPanel: boolean;
  projectHasFPD = false;

  public openUiElements() {
    this.isOpenUiElements = !this.isOpenUiElements;
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private websiteAnalyzeService: WebsiteAnalyzeService,
    private dataService: DataService,
    private userService: UserService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (
      this.router.url === '/home' ||
      this.router.url === '/home/pageLoad/new' ||
      this.router.url === '/buyPlan' ||
      this.router.url === '/home/myAccount' ||
      this.router.url === '/home/pageLoad/loading' ||
      this.router.url.includes('diff-alert')
    ) {
      this.show = false;
    } else {
      this.show = true;
    }
    if (this.router.url.slice(0, 5) === '/demo') {
      this.routeHome = '/demo/project/';
      this.isDemo = true;
    } else {
      this.url = this.route.snapshot.paramMap.get('id');
      this.routeHome = '/home/page/' + this.url;
      this.isDemo = false;
      this.expandPanel = this.router.url.includes('home/page');
      this.routeHomePathFirstParty =
        '/home/rum/' + this.url;
      this.showFirstPartyDetailSidebar = [
        'rum',
        'home/page',
      ].some((path) => this.router.url.includes(path));
    }

    if (this.router.url.split('/').pop() === 'rum') {
      this.showFirstPartyDetailSidebar = false;
      this.show = false;
    }
    this.isPageOnly = this.router.url.includes('-po');
    
    const projectId = this.route.snapshot.paramMap.get('id');
    try {
      if (projectId) {
        const website = await this.websiteAnalyzeService
          .getProjectByProjectId(projectId)
          .toPromise();
        this.projectHasFPD = website?.isFPDEnabled;
      }
    } catch (error) {}
  }
}
